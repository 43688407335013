import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Input, Button, Paper } from "@parallel-domain/pd-theme"
import { signIn, SignInResponse, signOut } from "next-auth/react"
import { Box, Container, Stack, Typography, CircularProgress } from "@mui/material"
import axios, { AxiosResponse } from "axios"
import { toast } from "react-toastify"
import parse from "html-react-parser"
import { Controller, useForm } from "react-hook-form"

// import app components
import useCustomSession from "features/authentication/hooks/useSession"
import type { UserApiError } from "../types"

const Reauth = () => {
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState<"email" | "pre-check" | "">("")

  const { handleSubmit, control } = useForm()

  const router = useRouter()

  const { data: session } = useCustomSession()

  useEffect(() => {
    if (session?.error === "re-authentication" && session?.user?.email) {
      handlePreLogin({ email: session.user.email })
    } else {
      signOut({ callbackUrl: router.asPath })
    }
  }, [session?.error])

  const handleLoginWithEmailPassword = async (formData: { password: string }) => {
    setLoading("email")

    const response: SignInResponse | undefined = await signIn("credentials", {
      email: session.user.email,
      ...formData,
      redirect: false,
    })

    if (response?.ok) {
      router.push("/datasets")
    } else {
      toast.error("Credentials invalid")
      setLoading("")
    }
  }

  const handlePreLogin = async (formData: { email: string }) => {
    try {
      setLoading("pre-check")

      const response: AxiosResponse<{ type: string; url?: string }> = await axios.post(
        `${process.env.NEXT_PUBLIC_PD_USER_API}/api/v1/auth/pre-login`,
        formData
      )

      if (response.data.type === "password") {
        setShowForm(true)
      } else if (response.data.type === "sso") {
        let url = response.data.url || ""

        if (router?.query?.callback) {
          const delimiter = url.includes("?") ? "&" : "?"
          url = `${url}${delimiter}state=${router.asPath}`
        }

        // Redirect to sso provider URL
        window.location.replace(url)
      }
    } catch (e) {
      const error = e as UserApiError
      toast.error(<>{parse(error.response?.data?.detail || "Something went wrong")}</>)
    } finally {
      setLoading("")
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 64px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="sm">
        <Paper>
          <Typography variant="h3" sx={{ textAlign: "center", mb: 3 }}>
            Please re-authenticate
          </Typography>

          <Paper
            backgroundDark={false}
            sx={{ minHeight: 250, display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            {showForm ? (
              <form
                method="post"
                noValidate
                onSubmit={(e) => {
                  e.preventDefault()

                  if (loading) return

                  handleSubmit(handleLoginWithEmailPassword)(e)
                }}
              >
                <Stack spacing={4}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Please enter your password",
                    }}
                    render={({ field: { onChange }, fieldState: { error } }) => {
                      return (
                        <Input
                          label="Password"
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          type="password"
                          fullWidth
                        />
                      )
                    }}
                  />

                  <Stack spacing={3}>
                    <Button loading={loading === "email"} variant="outlined" type="submit" fullWidth>
                      Login
                    </Button>
                  </Stack>
                </Stack>
              </form>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Paper>
      </Container>
    </Box>
  )
}

export default Reauth
