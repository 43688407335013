import { useEffect, useState, useCallback } from "react"
import { useRouter } from "next/router"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import LinearProgress from "@mui/material/LinearProgress"
import debounce from "lodash/debounce"
import { colors } from "@parallel-domain/pd-theme"

// import app components
import Nav from "components/nav"
import Reauth from "features/authentication/components/Reauth"
import useCustomSession from "features/authentication/hooks/useSession"
import useReleasesQuery from "features/releases/hooks/useReleasesQuery"
import Loader from "assets/icons/infinity_loader.svg"

export type PageWrapper = {
  isProtected: boolean
  showNavbar?: boolean
  children: React.ReactNode | React.ReactNode[]
}

const PageWrapper = (props: PageWrapper) => {
  const { isProtected, showNavbar = true, children } = props

  const { status, data: session } = useCustomSession()

  const router = useRouter()

  const [loading, setLoading] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)

  useReleasesQuery()

  // Instead of listening to the session status directly we gonna use a separate React state.
  // We are doing this because the update() function from session causes the session status to switch to "loading" for a brief second and as a result, the user sees a flash.
  // The useEffect hook in combination with a debouncer prevents this behavior.
  useEffect(() => {
    handleDebouncedStatusChange(status)
  }, [status])

  // Use debounce function to update data for performance improvement
  const handleDebouncedStatusChange = useCallback(
    debounce((status: string) => setAuthenticated(status === "authenticated"), 500),
    []
  )

  // Redirect user in case permission change (e.g. org switch)
  useEffect(() => {
    if (session?.user) {
      let redirect = false

      if (router.asPath.startsWith("/pd-sim") && !session.user?.activeOrganization?.capabilities.includes("step")) {
        redirect = true
      }

      if (
        router.asPath.startsWith("/settings/user-management") &&
        !session.user?.activeOrganization?.capabilities.includes("user_management")
      ) {
        redirect = true
      }

      if (
        router.asPath.startsWith("/settings/organization") &&
        !session.user?.activeOrganization?.capabilities.includes("organization_settings")
      ) {
        redirect = true
      }

      if (router.asPath.includes("/replicas") && !session.user?.activeOrganization?.capabilities.includes("replicas")) {
        redirect = true
      }

      if (redirect) {
        router.push("/datasets")
      }
    }
  }, [session?.user?.activeOrganization, router.asPath])

  useEffect(() => {
    const handleRouteChange = (newRoute) => {
      // Don't show loading indicator when logging in
      if (router.asPath === "/auth/login" && (newRoute === "/datasets" || newRoute === "/pd-sim/dashboard")) return

      setLoading(true)
    }

    const handleRouteComplete = () => {
      setLoading(false)
    }

    router.events.on("routeChangeStart", handleRouteChange)
    router.events.on("routeChangeComplete", handleRouteComplete)

    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
    }
  }, [router])

  const renderContent = () => {
    if (session?.error) {
      return <Reauth />
    }

    if (isProtected && !authenticated) {
      return (
        <Box
          sx={{
            flex: 1,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: colors.secondary[900],
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
            <Loader />
            <Typography sx={{ width: "100%", textAlign: "center" }}>
              Accelerating perception with synthetic data
            </Typography>
          </Box>
        </Box>
      )
    }

    return children
  }

  return (
    <>
      <Nav isVisible={showNavbar && authenticated}>{renderContent()}</Nav>

      <LinearProgress
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "2px",
          zIndex: 1200,
          opacity: loading ? 1 : 0,
          transition: "opacity 0.2s ease-in-out",
          pointerEvents: "none",
        }}
      />
    </>
  )
}

export default PageWrapper
