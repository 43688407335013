import { useEffect, useCallback } from "react"
import create from "zustand"
import { getSession, useSession as useNextAuthSession } from "next-auth/react"
import { Session } from "next-auth"

type SessionError = {
  message: string
  code: "re-authentication" | "refresh-token" | "fetch-error"
}

type Store = {
  session: Session | null
  loading: boolean
  error: SessionError | null
  sessionFetchPromise: Promise<Session | null> | null
  setSessionFetchPromise: (promise: Promise<Session | null> | null) => void
  setError: (error: SessionError | null) => void
  fetchSession: (force?: boolean) => Promise<Session | null>
  clearError: () => void
}

const useSessionStore = create<Store>((set, get) => ({
  session: null,
  loading: true,
  error: null,
  sessionFetchPromise: null,
  setSessionFetchPromise: (promise) => {
    set({ sessionFetchPromise: promise })
  },
  setError: (error) => {
    set({ error })
  },
  clearError: () => {
    set({ error: null })
  },
  fetchSession: async (force = false) => {
    const currentSession = get().session
    const currentTime = Date.now()
    const currentPromise = get().sessionFetchPromise

    // Clear any existing errors
    get().clearError()

    // If not forced and session is valid, return it
    if (!force && currentSession && currentTime < currentSession.accessTokenExpires - 1000 * 60) {
      return currentSession
    }

    // If we're already fetching the session and not forced, return the existing promise
    if (!force && currentPromise !== null) {
      return currentPromise
    }

    // Maximum retry attempts for session fetch
    const MAX_RETRIES = 3
    let retryCount = 0

    const attemptFetch = async (): Promise<Session | null> => {
      try {
        const sessionData = await getSession()

        // If NextAuth session has an error, propagate it
        if (sessionData?.error) {
          const sessionError: SessionError = {
            message: "Session error from NextAuth",
            code:
              sessionData.error === "refresh-token" || sessionData.error === "re-authentication"
                ? sessionData.error
                : "fetch-error",
          }
          set({ error: sessionError, loading: false })
          return sessionData
        }

        set({ session: sessionData, loading: false })
        get().setSessionFetchPromise(null)
        return sessionData
      } catch (error) {
        if (retryCount < MAX_RETRIES) {
          retryCount++
          await new Promise((resolve) => setTimeout(resolve, Math.pow(2, retryCount - 1) * 1000))
          return attemptFetch()
        }

        const sessionError: SessionError = {
          message: error instanceof Error ? error.message : "Failed to fetch session",
          code: "fetch-error",
        }
        set({ error: sessionError, loading: false })
        get().setSessionFetchPromise(null)
        throw error
      }
    }

    const promise = attemptFetch()
    get().setSessionFetchPromise(promise)
    return promise
  },
}))

const useCustomSession = () => {
  const { data: initialSession, update } = useNextAuthSession()
  const session = useSessionStore((state) => state.session)
  const error = useSessionStore((state) => state.error)
  const fetchSession = useSessionStore((state) => state.fetchSession)

  // Memoize the force refresh function
  const forceRefresh = useCallback(async () => {
    try {
      await fetchSession(true)
    } catch (error) {
      console.error("Force refresh failed:", error)
    }
  }, [fetchSession])

  // Determine which session to use based on the accessTokenExpires property
  let data: Session | null
  if (session && initialSession) {
    data = session.accessTokenExpires > initialSession.accessTokenExpires ? session : initialSession
  } else {
    data = session || initialSession
  }

  const status = error ? "error" : data ? "authenticated" : "loading"

  useEffect(() => {
    let interval: NodeJS.Timeout
    let mounted = true
    const abortController = new AbortController()

    const checkSessionValidity = async () => {
      try {
        if (mounted && !abortController.signal.aborted) {
          await fetchSession()
        }
      } catch (error) {
        console.error("Session refresh failed:", error)
      }
    }

    // Initial check and interval setup
    checkSessionValidity().then(() => {
      if (mounted && !abortController.signal.aborted) {
        interval = setInterval(checkSessionValidity, 30 * 1000)
      }
    })

    // Cleanup function
    return () => {
      mounted = false
      abortController.abort()
      if (interval) clearInterval(interval)
    }
  }, [fetchSession])

  return {
    data,
    status,
    update,
    error,
    forceRefresh,
  }
}

export default useCustomSession
